<template>
  <div class="form-styles-showcase">
    <div class="header">
      <router-link :to="{ name: 'form-components' }" class="back-button">
        <v-icon>mdi-arrow-left</v-icon>
        Back to Form Components
      </router-link>
      <h1>Form Input Styles</h1>
      <p class="subtitle">Showcase of all form input styles and mixins in the codebase</p>
      <div class="note">
        <v-icon small>mdi-information-outline</v-icon>
        <span>This showcase uses the original stylesheets from the codebase. Any changes to the stylesheets will be reflected here.</span>
      </div>
    </div>

    <div class="sections">
      <!-- Material Style Inputs -->
      <div class="section">
        <h2>Material Style Inputs <span class="source-file">src/assets/styles/components/_forms.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Default State</h3>
            <div class="component-demo">
              <div class="material_input">
                <input type="text" v-model="materialInputs.default" required>
                <label>Username</label>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Focused State</h3>
            <div class="component-demo">
              <div class="material_input">
                <input type="text" v-model="materialInputs.focused" required class="active">
                <label class="active">Email Address</label>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Filled State</h3>
            <div class="component-demo">
              <div class="material_input">
                <input type="text" v-model="materialInputs.filled" required>
                <label>Password</label>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Error State</h3>
            <div class="component-demo">
              <div class="material_input error">
                <input type="text" v-model="materialInputs.error" required>
                <label>Phone Number</label>
                <div class="error-msg">Invalid phone number format</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Input Style 2 -->
      <div class="section">
        <h2>Input Style 2 <span class="source-file">src/assets/styles/components/_forms.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Default State</h3>
            <div class="component-demo">
              <input type="text" class="input-style-2" placeholder="Enter your name" v-model="inputStyle2.default">
            </div>
          </div>

          <div class="component-card">
            <h3>Focused State</h3>
            <div class="component-demo">
              <input type="text" class="input-style-2 focused" placeholder="Enter your email" v-model="inputStyle2.focused">
            </div>
          </div>

          <div class="component-card">
            <h3>Error State</h3>
            <div class="component-demo">
              <input type="text" class="input-style-2 input_error" placeholder="Enter your password" v-model="inputStyle2.error">
              <div class="error-msg">Password is required</div>
            </div>
          </div>

          <div class="component-card">
            <h3>Textarea</h3>
            <div class="component-demo">
              <textarea class="textarea-style-2" placeholder="Enter your message" v-model="inputStyle2.textarea" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Input Style 3 -->
      <div class="section">
        <h2>Input Style 3 <span class="source-file">src/assets/styles/components/_forms.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Default State</h3>
            <div class="component-demo">
              <div class="input-style-3">
                <label>First Name</label>
                <input type="text" v-model="inputStyle3.default">
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Focused State</h3>
            <div class="component-demo">
              <div class="input-style-3">
                <label>Last Name</label>
                <input type="text" class="focused" v-model="inputStyle3.focused">
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Error State</h3>
            <div class="component-demo">
              <div class="input-style-3">
                <label>Email Address</label>
                <input type="text" class="error" v-model="inputStyle3.error">
                <div class="error-msg">Invalid email format</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Input Style 4 -->
      <div class="section">
        <h2>Input Style 4 <span class="source-file">src/assets/styles/components/_forms.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Default State</h3>
            <div class="component-demo">
              <div class="input-style-4">
                <label>Username</label>
                <input type="text" v-model="inputStyle4.default">
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Focused State</h3>
            <div class="component-demo">
              <div class="input-style-4">
                <label>Email</label>
                <input type="text" class="focused" v-model="inputStyle4.focused">
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Error State</h3>
            <div class="component-demo">
              <div class="input-style-4">
                <label>Password</label>
                <input type="password" class="error" v-model="inputStyle4.error">
                <div class="error-msg">Password must be at least 8 characters</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload Music Form Styles -->
      <div class="section">
        <h2>Upload Music Form Styles <span class="source-file">src/assets/styles/pages/_create-album.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Text Field</h3>
            <div class="component-demo">
              <div class="upload-music">
                <div class="v-input v-text-field">
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label>Track Title</label>
                        <input type="text" v-model="uploadMusic.title">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Textarea</h3>
            <div class="component-demo">
              <div class="upload-music">
                <div class="v-input v-textarea">
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label>Description</label>
                        <textarea v-model="uploadMusic.description" rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Disabled Input</h3>
            <div class="component-demo">
              <div class="upload-music">
                <div class="v-input v-text-field disabled">
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label>Release Date</label>
                        <input type="text" disabled value="2023-05-15">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Error State</h3>
            <div class="component-demo">
              <div class="upload-music">
                <div class="v-input v-text-field error">
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label>Artist Name</label>
                        <input type="text" v-model="uploadMusic.artist">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="error-msg">Artist name is required</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Login Form Styles -->
      <div class="section">
        <h2>Login Form Styles <span class="source-file">src/assets/styles/pages/_login.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Email Input</h3>
            <div class="component-demo login-demo">
              <div class="page-login">
                <div class="wrap_form_layout">
                  <div class="form">
                    <div class="email">
                      <label>Email</label>
                      <input type="email" v-model="loginForm.email">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Password Input</h3>
            <div class="component-demo login-demo">
              <div class="page-login">
                <div class="wrap_form_layout">
                  <div class="form">
                    <div class="password">
                      <label>Password</label>
                      <input type="password" v-model="loginForm.password">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Error State</h3>
            <div class="component-demo login-demo">
              <div class="page-login">
                <div class="wrap_form_layout">
                  <div class="form">
                    <div class="email">
                      <label>Email</label>
                      <input type="email" class="error" v-model="loginForm.errorEmail">
                      <div class="error-msg">Please enter a valid email address</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Input Transition Mixin -->
      <div class="section">
        <h2>Input Transition Mixin <span class="source-file">src/assets/styles/pages/_profile-basics.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Mixin Definition</h3>
            <div class="component-demo code-block">
              <pre>@mixin input-transition {
  transition: border-color 0.2s ease;
}</pre>
            </div>
          </div>

          <div class="component-card">
            <h3>Applied to Input</h3>
            <div class="component-demo">
              <input type="text" class="transition-input" placeholder="Hover or focus me" v-model="transitionInput">
            </div>
          </div>
        </div>
      </div>

      <!-- Dialog Input Styles -->
      <div class="section">
        <h2>Dialog Input Styles <span class="source-file">src/assets/styles/mixins/_dialog-mixins.scss</span></h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Dialog Input</h3>
            <div class="component-demo">
              <div class="dialog-content">
                <input type="text" placeholder="Enter value" v-model="dialogInput">
              </div>
            </div>
          </div>

          <div class="component-card">
            <h3>Focused State</h3>
            <div class="component-demo">
              <div class="dialog-content">
                <input type="text" placeholder="Enter value" class="focused" v-model="dialogInputFocused">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormStylesShowcase',
  data: () => ({
    materialInputs: {
      default: '',
      focused: 'user@example.com',
      filled: 'password123',
      error: '123-456'
    },
    inputStyle2: {
      default: '',
      focused: 'user@example.com',
      error: '',
      textarea: 'This is a sample message text for the textarea component.'
    },
    inputStyle3: {
      default: '',
      focused: 'Smith',
      error: 'invalid-email'
    },
    inputStyle4: {
      default: '',
      focused: 'user@example.com',
      error: 'pass'
    },
    uploadMusic: {
      title: 'New Track',
      description: 'This is a description of the new track being uploaded.',
      artist: ''
    },
    loginForm: {
      email: '',
      password: '',
      errorEmail: 'invalid@'
    },
    transitionInput: '',
    dialogInput: '',
    dialogInputFocused: 'Dialog input value'
  })
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

/* Showcase specific styles */
.form-styles-showcase {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  .header {
    margin-bottom: 3rem;
    text-align: center;
    position: relative;

    .back-button {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $color-accent;
      font-size: 1.1rem;
      transition: color 0.3s ease;

      &:hover {
        color: lighten($color-accent, 10%);
      }

      .v-icon {
        margin-right: 0.5rem;
      }
    }

    h1 {
      color: $text-primary;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: $text-secondary;
      font-size: 1.1rem;
    }
    
    .note {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-accent;
      font-size: 0.9rem;
      
      .v-icon {
        margin-right: 0.5rem;
      }
    }
  }

  .section {
    margin-bottom: 3rem;

    h2 {
      color: $text-primary;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba($border-color, 0.3);

      .source-file {
        font-size: 0.9rem;
        color: $color-accent;
        font-weight: normal;
        margin-left: 1rem;
      }
    }

    .component-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;
    }
  }

  .component-card {
    background: $input-bg;
    border: 1px solid $color-secondary;
    border-radius: 12px;
    padding: 1.5rem;

    h3 {
      color: $text-primary;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    .component-demo {
      padding: 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      min-height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      
      &.code-block {
        pre {
          color: $color-accent;
          font-family: monospace;
          margin: 0;
          white-space: pre-wrap;
        }
      }
      
      &.login-demo {
        height: 110px;
      }
    }
  }
  
  // Ensure login demo has proper height and structure
  .login-demo {
    .page-login {
      display: flex;
      align-items: flex-start;
      height: 100%;
      
      .form {
        flex: 1;
        
        .email, .password {
          margin-bottom: 0; // Override margin to fit in card
        }
      }
    }
  }
  
  // Input Transition Mixin Demo
  .transition-input {
    border: 1px solid $color-secondary;
    background-color: $input-bg;
    border-radius: 5px;
    padding: 0 16px;
    height: 42px;
    color: $text-primary;
    font-size: 16px;
    width: 100%;
    @include input-transition;

    &::placeholder {
      color: $text-secondary;
    }

    &:hover {
      border-color: $color-accent;
    }

    &:focus {
      outline: none;
      border-color: $color-accent;
    }
  }
}
</style> 